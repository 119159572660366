var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[(!_vm.loaded && !_vm.loadingError)?_c('b-row',{staticClass:"mt-5"},[_c('b-col',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Spinning"}})],1)],1):_vm._e(),(_vm.loadingError)?_c('b-row',{staticClass:"mt-5"},[_c('b-col',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.loadingError))])],1):_vm._e(),(_vm.taskAttribute == false)?_c('b-row',{staticClass:"mt-5"},[_c('b-col',{staticClass:"text-center"},[_vm._v("Not found")])],1):_vm._e(),(_vm.loaded)?_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',[(_vm.taskAttribute.ParentTaskAttribute)?[_vm._v(" Parent: "+_vm._s(_vm.taskAttribute.ParentTaskAttribute.Description)),_c('small',{staticClass:"text-muted"},[_vm._v("  "+_vm._s(_vm.taskAttribute.ParentTaskAttribute.TaskReference)+" ")])]:[_vm._v(" "+_vm._s(_vm.taskAttribute.Description)),_c('small',{staticClass:"text-muted"},[_vm._v("  "+_vm._s(_vm.taskAttribute.TaskReference))])]],2),(_vm.taskAttribute.ParentTaskAttribute)?_c('h2',[_vm._v(_vm._s(_vm.taskAttribute.Description)),_c('small',{staticClass:"text-muted"},[_vm._v("  "+_vm._s(_vm.taskAttribute.TaskReference))])]):_vm._e()]),(
        _vm.taskAttribute.ParentTaskAttributeID && (
          _vm.$root.$data.me.IsSuperUser || 
          ['CP1', 'CP2'].includes(_vm.taskAttribute.ParentTaskAttribute.BestServiceTag)
        )
      )?_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":{ 
          name: 'task-detail-overview', 
          params: { id: _vm.taskAttribute.ParentTaskAttributeID } 
        }}},[_vm._v("Go to parent - "+_vm._s(_vm.taskAttribute.ParentTaskAttribute.Description)+"...")])],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('ul',{staticClass:"nav nav-pills"},_vm._l((_vm.visibleTabs),function(tab){return _c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{ 
                active: _vm.$route.fullPath == tab.to,
              },attrs:{"to":tab.to}},[_vm._v(_vm._s(tab.title))])],1)}),0)]),_c('router-view',{staticClass:"px-0",attrs:{"task-attribute":_vm.combinedTaskAttribute,"holder":_vm.combinedTaskAttribute,"holder-type":"TaskAttribute","holder-id":_vm.taskAttribute.TaskAttributeID}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }